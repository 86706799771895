import { observer } from "mobx-react";
import React from "react";
import { RouteComponentProps } from "react-router";
import Perm from "../../../../app/Perm";
import { ManufacturingState } from "../../../../app/WebAPIClients";
import Consts from "../../../../components/Consts";
import PagingControls from "../../../../components/PagingControls";
import PermCtl from "../../../../components/PermCtrl";
import Util from "../../../../components/Util";
import "./PageReportManufacturing.styl";
import Store from "./store";

const { ManufacturingState: ManufacturingStateUtil } = Consts;

declare interface IPageReportManufacturingProps
  extends RouteComponentProps<{}> {}

declare interface IPageReportManufacturingState extends IActionErrorState {
  autocycle: boolean;
}

@observer
class PageReportManufacturing extends React.Component<
  IPageReportManufacturingProps,
  IPageReportManufacturingState
> {
  private timeoutId: NodeJS.Timeout;
  private echartsDom: HTMLDivElement;
  constructor(props) {
    super(props);
    this.state = {
      autocycle: Util.getLocState(this, "autocycle", true),
    };
  }

  public async componentDidMount() {
    await this.loadData();
    if (this.state.autocycle) {
      this.timeoutId = setTimeout(
        // 设置定时器，30秒后跳转到仪表板
        () => {
          this.props.history.push({
            pathname: "/dashboard",
            state: { autocycle: this.state.autocycle },
          });
        },
        30 * 1000,
      );
    }
  }

  public componentWillUnmount() {
    this.clearTimeout();
  }

  public render() {
    const t = this;
    const s = t.state;
    if (!PermCtl.isAuthorized(Perm.RPT_DB_R)) {
      return <div>当前用户没有权限查看此页面，请联系系统管理员分配权限</div>;
    }
    return (
      <div>
        {Store.reportData ? (
          <div
            className="realtimeReportBackground"
            ref={(dom) => {
              this.echartsDom = dom;
            }}
          />
        ) : (
          <div className="t-PL10 t-LH44 t-FBH t-FBAC t-FBJC">
            {s.error ? "加载数据错误" : "没有数据"}
          </div>
        )}
        <PagingControls
          autocycle={s.autocycle}
          onPreviousReportClick={t.handlePreviousReportClick}
          onNextReportClick={t.handleNextReportClick}
          onPauseClick={t.handlePauseClick}
          onContinueClick={t.handleContinueClick}
        />
      </div>
    );
  }

  private getState(manufacturing: ManufacturingState) {
    return ManufacturingStateUtil.getState(manufacturing).text;
  }

  private getOption() {
    const t = this;
    const s = this.state;
    Store.reportData.workOrderByState.reverse(); // 倒序存储返回的数据，让生产工序从领料审批开始递增

    const option = {
      backgroundColor: "black",
      title: {
        text: "工单实时状态",
        textStyle: {
          fontWeight: "500",
          fontSize: 50,
          color: "white",
        },
        subtext: "总计：" + Store.reportData.totalWorkOrdersCount + " 个工单",
        subtextStyle: {
          fontSize: 20,
        },
        itemGap: 20,
        x: "center",
        y: "5%",
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },
      grid: {
        top: "20%",
        left: "8%",
        right: "8%",
        bottom: "8%",
        containLabel: true,
      },
      yAxis: {
        type: "value",
        boundaryGap: [0, 0.01],
        axisLabel: {
          formatter: "{value}",
          textStyle: {
            fontSize: 30,
            color: "white",
          },
        },
        axisLine: {
          lineStyle: {
            color: "white",
          },
        },
      },
      xAxis: {
        type: "category",
        data: Store.reportData.workOrderByState.map((d) => {
          return t.getState(d.state);
        }),
        axisLabel: {
          show: true,
          textStyle: {
            fontSize: 25,
            color: "white",
          },
        },
        axisLine: {
          lineStyle: {
            color: "white",
          },
        },
      },
      series: [
        {
          type: "bar",
          data: Store.reportData.workOrderByState.map((d) => {
            return d.totalCount;
          }),
          label: {
            normal: {
              show: true,
              position: "top",
              textStyle: {
                color: "rgba(255,255,255,1)",
                fontSize: 25,
              },
              formatter: Store.reportData.workOrderByState.map((d) => {
                return d.totalCount;
              }),
            },
          },
          barWidth: "50%",
          itemStyle: {
            normal: {
              color: "#40cabf",
            },
          },
        },
      ],
    };

    return option;
  }

  private async renderEChart() {
    const ECharts = await import(
      /* webpackChunkName: "echarts" */ "../../../../components/ECharts"
    );
    const chart = ECharts.default.init(this.echartsDom);
    chart.setOption(this.getOption());
  }

  private async loadData() {
    await Store.loadData();
    if (!Store.hasError) {
      this.renderEChart();
    }
  }

  private handlePreviousReportClick = () => {
    if (this.timeoutId) {
      this.clearTimeout();
    }
    this.props.history.push({
      pathname: "/realtimereport",
      state: { autocycle: this.state.autocycle },
    });
  };

  private handleNextReportClick = () => {
    if (this.timeoutId) {
      this.clearTimeout();
    }
    this.props.history.push({
      pathname: "/dashboard",
      state: { autocycle: this.state.autocycle },
    });
  };

  private handlePauseClick = () => {
    this.setState({ autocycle: false });
    this.clearTimeout();
  };

  private handleContinueClick = () => {
    this.setState({ autocycle: true });
    this.timeoutId = setTimeout(() => {
      this.props.history.push({
        pathname: "/dashboard",
        state: { autocycle: this.state.autocycle },
      });
    }, 30 * 1000);
  };

  private clearTimeout() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }
}

export default PageReportManufacturing;
