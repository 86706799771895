import { Alert, Card, Col, DatePicker, message, Row } from "antd";
import React from "react";
import PagingControls from "../../../../components/PagingControls";
import Util from "../../../../components/Util";
import "./PageRealTimeReport.styl";
import Store from "./store";

import { observer } from "mobx-react";
// tslint:disable-next-line:no-implicit-dependencies
import moment from "moment";
import { RouteComponentProps } from "react-router";
import Perm from "../../../../app/Perm";
import PermCtl from "../../../../components/PermCtrl";
moment.locale("zh-cn");

const { RangePicker } = DatePicker;
const dateFormat = "YYYY-MM-DD";

declare interface IPageRealTimeReportProps extends RouteComponentProps<{}> {}
declare interface IPageRealTimeReportState extends IActionErrorState {
  autocycle: boolean;
}

@observer
class PageRealTimeReport extends React.Component<
  IPageRealTimeReportProps,
  IPageRealTimeReportState
> {
  private timeoutId: NodeJS.Timeout;
  constructor(props) {
    super(props);
    this.state = {
      autocycle: Util.getLocState(this, "autocycle", true),
    };
  }

  public componentDidMount() {
    this.loadData();
    if (this.state.autocycle) {
      this.timeoutId = setTimeout(
        // 设置定时器，30秒后跳转到生产报表
        () => {
          this.props.history.push({
            pathname: "/reportmanufacturing",
            state: { autocycle: this.state.autocycle },
          });
        },
        30 * 1000,
      );
    }
  }

  public componentWillUnmount() {
    this.clearTimeout();
  }

  public render() {
    const t = this;
    const s = t.state;

    if (!PermCtl.isAuthorized(Perm.RPT_DB_R)) {
      return <div>当前用户没有权限查看此页面，请联系系统管理员分配权限</div>;
    }
    const currentDate = moment().format(dateFormat);

    const prefix =
      currentDate === Store.startDate && Store.startDate === Store.endDate
        ? "今日"
        : "";

    return (
      <div>
        {Store.result ? (
          <div>
            <div className="reportCard realtimeReportBackground">
              <Row>
                <Col span={8} />
                {/* 占位，让显示内容居中 */}
                <Col span={8}>
                  <RangePicker
                    className="selectDate"
                    allowClear={false}
                    size={"large"}
                    style={{
                      width: "100%",
                      backgroundColor: "black",
                    }}
                    defaultValue={[
                      moment(Store.startDate),
                      moment(Store.endDate),
                    ]}
                    ranges={{
                      本月: [
                        moment().startOf("month"),
                        moment().endOf("month"),
                      ],
                      上个月: [
                        moment().subtract(1, "month").startOf("month"),
                        moment().subtract(1, "month").endOf("month"),
                      ],
                    }}
                    format={dateFormat}
                    onChange={t.handleStartEndDatesChange}
                  />
                </Col>
                <Col span={8} />
                {/* 占位，让显示内容居中 */}
              </Row>
              <div style={{ marginBottom: 40 }}>
                <Row justify="space-around" className="rowMargin">
                  <Col span={6} />
                  {/* 占位，让显示内容居中 */}
                  <Col span={6}>
                    <Card bordered={false} className="textAlign">
                      <p className="textStyle">
                        {prefix}
                        新建工单数量(个)
                      </p>
                      <p className="valueStyle">
                        {Store.result.totalNewWorkOrdersCount}
                      </p>
                    </Card>
                  </Col>
                  <Col span={6}>
                    <Card bordered={false} className="textAlign">
                      <p className="textStyle">
                        {prefix}
                        完成工单数量(个)
                      </p>
                      <p className="valueStyle">
                        {Store.result.totalWorkOrdersOfCompeletedCount}
                      </p>
                    </Card>
                  </Col>
                  <Col span={6} />
                  {/* 占位，让显示内容居中 */}
                </Row>
              </div>
              <div className="rowBorder">
                <Row>
                  <Col span={12} className="colBorder">
                    <Row justify="space-around" className="rowMargin">
                      <Col span={12}>
                        <Card bordered={false} className="textAlign">
                          <p className="textStyle">
                            {prefix}
                            荒料入库数量(颗)
                          </p>
                          <p className="valueStyle">
                            {Store.result.totalNewBlockCount}
                          </p>
                        </Card>
                      </Col>
                      <Col span={12}>
                        <Card bordered={false} className="textAlign">
                          <p className="textStyle">
                            {prefix}
                            荒料出库数量(颗)
                          </p>
                          <p className="valueStyle">
                            {Store.result.totalBlocksOfStockOutCount}
                          </p>
                        </Card>
                      </Col>
                    </Row>
                    <Row justify="space-around" className="rowMargin">
                      <Col span={12}>
                        <Card bordered={false} className="textAlign">
                          <p className="textStyle">
                            {prefix}
                            大板入库数量(扎)
                          </p>
                          <p className="valueStyle">
                            {Store.result.totalNewStoneBundlesCount}
                          </p>
                        </Card>
                      </Col>
                      <Col span={12}>
                        <Card bordered={false} className="textAlign">
                          <p className="textStyle">
                            {prefix}
                            大板出库数量(扎)
                          </p>
                          <p className="valueStyle">
                            {Store.result.totalStoneBundlesOfStockOutCount}
                          </p>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={12}>
                    <Row justify="space-around" className="rowMargin">
                      <Col span={12}>
                        <Card bordered={false} className="textAlign ">
                          <p className="textStyle">
                            {prefix}
                            大板产量(扎)
                          </p>
                          <p className="valueStyle">
                            {
                              Store.result
                                .totalNewStoneBundlesForManufacturingCount
                            }
                          </p>
                        </Card>
                      </Col>
                      <Col span={12}>
                        <Card bordered={false} className="textAlign">
                          <p className="textStyle">
                            {prefix}
                            大板产量(平方米)
                          </p>
                          <p className="valueStyle">
                            {Store.result.totalAreaForManufacturing}
                          </p>
                        </Card>
                      </Col>
                    </Row>
                    <Row justify="space-around" className="rowMargin">
                      <Col span={12}>
                        <Card bordered={false} className="textAlign">
                          <p className="textStyle">
                            {prefix}
                            工程板产量(平方米)
                          </p>
                          <p className="valueStyle">
                            {Store.result.totalNewTileArea}
                          </p>
                        </Card>
                      </Col>
                      <Col span={12}>
                        <Card bordered={false} className="textAlign">
                          <p className="textStyle">
                            {prefix}
                            工程板出库量(平方米)
                          </p>
                          <p className="valueStyle">
                            {Store.result.totalTileOfStockOutArea}
                          </p>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        ) : (
          <Alert
            message="没有数据"
            description="没有获取到数据"
            type="info"
            showIcon={true}
          />
        )}
        <PagingControls
          autocycle={s.autocycle}
          onPreviousReportClick={t.handlePreviousReportClick}
          onNextReportClick={t.handleNextReportClick}
          onPauseClick={t.handlePauseClick}
          onContinueClick={t.handleContinueClick}
        />
      </div>
    );
  }

  private handlePreviousReportClick = () => {
    if (this.timeoutId) {
      this.clearTimeout();
    }
    this.props.history.push({
      pathname: "/dashboard",
      state: { autocycle: this.state.autocycle },
    });
  };

  private handleNextReportClick = () => {
    if (this.timeoutId) {
      this.clearTimeout();
    }
    this.props.history.push({
      pathname: "/reportmanufacturing",
      state: { autocycle: this.state.autocycle },
    });
  };

  private handlePauseClick = () => {
    this.setState({ autocycle: false });
    this.clearTimeout();
  };

  private handleContinueClick = () => {
    this.setState({ autocycle: true });
    this.timeoutId = setTimeout(() => {
      this.props.history.push({
        pathname: "/reportmanufacturing",
        state: { autocycle: this.state.autocycle },
      });
    }, 30 * 1000);
  };

  private loadData = async () => {
    await Store.getRealTimeReport();
  };

  private verifyDate(startDate: string, endDate: string) {
    if (
      startDate === null ||
      endDate === null ||
      typeof startDate === "undefined" ||
      typeof endDate === "undefined"
    ) {
      message.info("日期不合法");
      return false;
    }

    if (moment(endDate).isBefore(startDate)) {
      message.info("结束时间不能早于开始时间");
      return false;
    }

    return true;
  }

  private handleStartEndDatesChange = async (
    dates: any,
    dateStrings: [string, string],
  ) => {
    const t = this;
    const startDate = dateStrings[0];
    const endDate = dateStrings[1];

    // 在日期发生变化后需重新获取数据,所以需要验证日期
    if (!t.verifyDate(startDate, endDate)) {
      return;
    }

    Store.UpdateDate(startDate, endDate);

    // 在日期发生变化后，重新获取数据
    await this.loadData();
  };

  private clearTimeout() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }
}

export default PageRealTimeReport;
